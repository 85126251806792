<template>
  <div class="data-overview">
    <el-row v-if="isLoad" :gutter="10">
      <el-col :lg="18" :xl="18">
        <el-row :gutter="10">
          <el-col :span="5">
            <rentalPlazaVue :table-list="tableList" />
          </el-col>
          <el-col :span="9">
            <userDataVue :table-list="tableList" />
          </el-col>
          <el-col :span="5">
            <listingsOthersVue :table-list="tableList" />
          </el-col>
          <el-col :span="5">
            <financialDataVue :table-list="tableList" />
          </el-col>
        </el-row>
        <el-row :gutter="10" style="margin: 10px 0">
          <el-col :span="8" style="padding-left: 0">
            <merchantListingRankingVue />
          </el-col>
          <el-col :span="8">
            <merchantTypeVue />
          </el-col>
          <el-col :span="8" style="padding-right: 0">
            <merchantCityDistributionVue />
          </el-col>
        </el-row>
      </el-col>
      <el-col :lg="6" :xl="6">
        <el-row>
          <el-col :span="24"><paymentAmountVue /></el-col>
        </el-row>
        <el-row style="margin-top: 10px">
          <el-col :span="24"><merchantGrowthVue /></el-col>
        </el-row>
        <el-row style="margin-top: 10px">
          <el-col :span="24"><listingGrowthVue /></el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import merchantListingRankingVue from './components/merchantListingRanking.vue'
  import merchantGrowthVue from './components/merchantGrowth.vue'
  import paymentAmountVue from './components/paymentAmount.vue'
  import financialDataVue from './components/financialData.vue'
  import rentalPlazaVue from './components/rentalPlaza.vue'
  import listingsOthersVue from './components/listings&others.vue'
  import userDataVue from './components/userData.vue'
  import merchantTypeVue from './components/merchantType.vue'
  import merchantCityDistributionVue from './components/merchantCityDistribution.vue'
  import listingGrowthVue from './components/listingGrowth.vue'
  import { totalListAPI } from '@/api/home/newIndex'
  import { numberToCurrencyNo } from '../../utils/index'
  export default {
    name: 'Index',
    components: {
      rentalPlazaVue,
      financialDataVue,
      listingsOthersVue,
      userDataVue,
      paymentAmountVue,
      merchantGrowthVue,
      merchantTypeVue,
      merchantListingRankingVue,
      merchantCityDistributionVue,
      listingGrowthVue,
    },
    data() {
      return {
        tableList: [],
        isLoad: false,
      }
    },
    computed: {
      totalData() {
        return this.tableList
      },
    },
    mounted() {
      this.getTotalList()
    },
    methods: {
      getTotalList() {
        totalListAPI().then((res) => {
          this.tableList = (res.data || []).map((ele) => {
            return {
              ...ele,
              value: numberToCurrencyNo(ele.value, 3),
              added: numberToCurrencyNo(ele.added, 3),
            }
          })
          this.isLoad = true
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .data-overview {
    background: #f6f8f9;
  }
</style>
